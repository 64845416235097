export default {
  cake: {
    56: '0x5c46c55A699A6359E451B2c99344138420c87261',
    97: '0x5c46c55A699A6359E451B2c99344138420c87261',
  },
  masterChef: {
    56: '0xE8674316F5634f1F9Bdd230c6D378c7b12eF4A23',
    97: '0x98d7Af25DC6652FaDF593c01F84bfd7a52b5B864',
  },
  wbnb: {
    56: '0x2bB33e072890C9642452eCF08ad0AEF63A57bB83',
    97: '0x2bB33e072890C9642452eCF08ad0AEF63A57bB83',
  },
  lottery: {
    56: '',
    97: '',
  },

  usdt :{
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '',
  },

  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
