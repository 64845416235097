import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import farmsConfig from 'config/constants/farms'
import { getMasterChefAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchFarmUserAllowances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAdress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}


export const fetchFarmUserStakingDetails = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })


  // uint256 stakedAmountTotal;
  // uint256 pendingRewardTotal; 
  // uint256 settledPendingReward;
  // uint256 settledStakedAmount;
  // uint256 lastClaimed;
  // uint256 claims;
  // bool wasPremeture;
  // uint256 withdrawlTime;


  const rawStakedBalances = await multicall(masterchefABI, calls)
  const totalStakedAmount = rawStakedBalances.map((stakedBalance) => {
    const data = stakedBalance.claimInfo.stakedAmountTotal
    return new BigNumber(data._hex).toJSON()
  })


  const totalRewardAmount = rawStakedBalances.map((stakedBalance) => {
    const data = stakedBalance.claimInfo.pendingRewardTotal

    return new BigNumber(data._hex).toJSON()
  })
  
  const settledPendingReward = rawStakedBalances.map((stakedBalance) => {
    const data = stakedBalance.claimInfo.settledPendingReward

    return new BigNumber(data._hex).toJSON()
  })

  const settledStakedAmount = rawStakedBalances.map((stakedBalance) => {
    const data = stakedBalance.claimInfo.settledStakedAmount

    return new BigNumber(data._hex).toJSON()
  })

  const totalClaims = rawStakedBalances.map((stakedBalance) => {
    const data = stakedBalance.claimInfo.claims
    console.log("poqqqqq",data)

    return new BigNumber(data._hex).toJSON()
  })

  const nextClaims = rawStakedBalances.map((stakedBalance) => {
    let data = stakedBalance.claimInfo.lastClaimed
    data  = new BigNumber(data._hex).toNumber()+604800
    return data
  })

  const isPremeture = rawStakedBalances.map((stakedBalance) => {
    return stakedBalance.claimInfo.wasPremeture
  })


  return  {totalStakedAmount,isPremeture,nextClaims,totalClaims,
    settledStakedAmount,
    settledPendingReward,

    totalRewardAmount
  }
}













export const fetchFarmUserEarnings = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'pendingIBG',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}


export const fetchFarmUserDepositTime = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const checkpoints = await multicall(masterchefABI, calls)


  const parsedCheckpoint = checkpoints.map((checkpoint) => {
    return new BigNumber(checkpoint[2]._hex).toJSON()
  })

  return parsedCheckpoint
}






