import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [



  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    duration:4,
    lpSymbol: 'iBG',
    lpAddresses: {
      97: "0x2bB33e072890C9642452eCF08ad0AEF63A57bB83",
      56:"0x36B8eE5c1f8FFB3b224736Ca9c989B1c531a613d", // EGG-BUSD LP
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97:"0x2bB33e072890C9642452eCF08ad0AEF63A57bB83",
      56: contracts.cake[56], // EGG-BUSD LP
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,
    duration:5,

    lpSymbol: 'iBG',
    lpAddresses: {
      97: "0x2bB33e072890C9642452eCF08ad0AEF63A57bB83",
      56:"0x36B8eE5c1f8FFB3b224736Ca9c989B1c531a613d", // EGG-BUSD LP
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97:"0x2bB33e072890C9642452eCF08ad0AEF63A57bB83",
      56: contracts.cake[56], // EGG-BUSD LP
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 2,
    risk: 5,
    isTokenOnly: true,
    duration:6,

    lpSymbol: 'iBG',
    lpAddresses: {
      97: "0x2bB33e072890C9642452eCF08ad0AEF63A57bB83",
      56:"0x36B8eE5c1f8FFB3b224736Ca9c989B1c531a613d", // EGG-BUSD LP
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97:"0x2bB33e072890C9642452eCF08ad0AEF63A57bB83",
      56: contracts.cake[56], // EGG-BUSD LP
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



]

export default farms
